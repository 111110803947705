import {
  Anchor,
  Box,
  Button,
  Card,
  Center,
  Container,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import Link from "next/link";
import React from "react";
import ButtonLogin from "./components/ButtonLogin";
import { InputLogin } from "./components/InputLogin";
import useStyles from "./styles";
const Login = () => {
  const { classes } = useStyles();
  return (
    <Container size="sm">
      <Center className={classes.paddingVerticalTitle}>
        <Title order={3}>Chào mừng đến ZeMor Coffee!</Title>
      </Center>
      <Card className={classes.container} withBorder>
        <Center>
          <Title order={5}>Đăng nhập</Title>
        </Center>
        <Box className={classes.labelFormLogin}>
          <Text>
            Đăng nhập để có trải nghiệm thanh toán nhanh hơn và hưởng các ưu đãi
            thành viên.
          </Text>
        </Box>
        <InputLogin />

        <ButtonLogin title="Quên mật khẩu" href="/" />
        <ButtonLogin title="Chưa có tài khoản? Đăng ký ngay" href="/" />
      </Card>
    </Container>
  );
};

export default Login;
